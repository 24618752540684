export default function (t) {
	return {
		ds: [
			{
				"desc": t("financial_table_label_1"),
				"operation": "%",
				"indicator1": "178.831",
				"indicator2": "174.282",
				"indicator3": "171.126"
			},
			{
				"desc": t("financial_table_label_2"),
				"operation": "%",
				"indicator1": "46.117",
				"indicator2": "40.694",
				"indicator3": "44.850"

			},
			{
				"desc": t("financial_table_label_3"),
				"operation": "EURO",
				"indicator1": "7,188,738",
				"indicator2": "9,396,226",
				"indicator3": "7,164,513"
			}
		]
	}
}