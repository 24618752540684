import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";

import translationEN from './locales/en/translation.json';
import translationRO from './locales/ro/translation.json';
import translationDE from './locales/de/translation.json';

// the translations
const resources = {
  en: {
		translation: translationEN,
	},
	ro: {
		translation: translationRO,
	},
	de: {
		translation: translationDE
  }
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
		resources,
    lng: "ro",
    fallbackLng: "ro",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;