const PHRASES = 13;

export default function (t) {
	return {
		technical: [...Array(PHRASES).keys()].map((idx) => {
			idx = idx + 1;
			if (idx === 1)
				return {text: t("technical_base_" + idx) }
			else
			return { icon: true, text: t("technical_base_" + idx) }
		})
	}
}