import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

export class MapContainer extends React.Component {

	constructor(props) {
		super(props);

		this.mapStyles = {
			width: '270px',
			height: '240px'
		}
	}

	render() {
		return (
			<Map
				google={window.google}
				zoom={16}
				style={this.mapStyles}
				initialCenter={{ lat: 44.4316545, lng: 24.385720499999934 }}>
				<Marker onClick={this.onMarkerClick}
					name={'SCADT Slatina'} />
			</Map>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: "AIzaSyCcGEYeGnfMS9zEmz2mgZBfRBsbi84cgYc"
})(MapContainer)