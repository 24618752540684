import React from 'react';
import { Breadcrumbs, Link, withStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import CommuteIcon from '@material-ui/icons/Commute';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AssessmentIcon from '@material-ui/icons/Assessment';

const styleSheet = {
	root: {
		padding: 1,
		marginLeft: 10
	},
	link: {
		display: "flex",
		textTransform: "capitalize"
	},
	icon: {
		width: 20,
		height: 20,
		padding: 1
	},
};


class PageTitle extends React.Component {
	getIcon = (type) => {
		switch (type) {
			// Projects
			case 1:
				return <BusinessIcon className={this.props.classes.icon} />
			// Technical Base
			case 2:
				return <BuildIcon className={this.props.classes.icon} />
			// Certifications
			case 3:
				return <AssignmentIcon className={this.props.classes.icon} />
			// Structure
			case 4:
				return <CommuteIcon className={this.props.classes.icon} />
			// Financial
			case 5:
				return <MonetizationOnIcon className={this.props.classes.icon} />
			// Portoflio
			case 6:
				return <AssessmentIcon className={this.props.classes.icon} />
			default:
				return null;
		}
	}
	render = () => {
		return (
			<Breadcrumbs aria-label="breadcrumb" className={this.props.classes.root}>
				<Link color="inherit" href="/" className={this.props.classes.link}>
					<HomeIcon className={this.props.classes.icon} />
					{this.props.labelAboutUs}
				</Link>
				{
					this.props.labelCurrentPage
						? <Link color="inherit" href={window.location.pathname} className={this.props.classes.link}>
							{this.getIcon(this.props.labelType)}
							{this.props.labelCurrentPage}
						</Link>
						: null
				}
			</Breadcrumbs>
		);
	}
}

export default withStyles(styleSheet)(PageTitle);