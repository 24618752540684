import React from 'react';
import Home from './Pages/Home';
import Projects from './Pages/Projects';
import Certifications from './Pages/Certifications';
import TehnicalBase from './Pages/TehnicalBase';
import Structure from './Pages/Structure';
import Financial from './Pages/Financial';
import Portfolio from './Pages/Portfolio';
import { Route, Switch } from 'react-router-dom';

class Right extends React.Component {
	render() {
		return <div className="App-content-right">
			<Switch>
				<Route exact path="/" component={Home} />
				<Route path="/proiecte" component={Projects} />
				<Route path="/dotare" component={TehnicalBase} />
				<Route path="/certificari" component={Certifications} />
				<Route path="/structura" component={Structure} />
				<Route path="/financiar" component={Financial} />
				<Route path="/portfolio" component={Portfolio} />
			</Switch>
		</div>
	}
}

export default Right;