import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import {
	AppBar, Toolbar, List, ListItem, Button,
	withStyles, Grid, SwipeableDrawer, Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import FlagIcon from './FlagIcon';
import i18n from '../i18n';

const styleSheet = {
	list: {
		width: 200,
	},
	link: {
		marginLeft: 20,
		cursor: "pointer",
		color: "white",
		textTransform: "uppercase"
	},
	img: {
		marginLeft: 8,
		cursor: "pointer",
		color: "white",
	},
	sideBarIcon: {
		padding: 0,
		color: "white",
		cursor: "pointer",
	},
	appBar: {
		background: "#2a2a2a",
		padding: "0 0 0 0",
		width: "100%"
	}
}

class ResAppBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			smallScreen: window.innerWidth <= 600,
			drawer: false
		};

		this.links = [
			{ text: "DESPRE NOI", url: "" },
			{ text: "PROIECTE", url: "" },
			{ text: "DOTARE", url: "" },
			{ text: "CERTIFICARI", url: "" },
			{ text: "STRUCTURA", url: "" },
			{ text: "FINANCIAR", url: "" },
			{ text: "PORTFOLIU", url: "" }
		];

		this.createMenuMobile = this.createMenuMobile.bind(this);
		this.createMenuDesktop = this.createMenuDesktop.bind(this);
		this.resizeWindow = this.resizeWindow.bind(this);
	}

	UNSAFE_componentWillMount = () => {
		window.addEventListener('resize', () => {
			this.setState({ smallScreen: window.innerWidth <= 600 });
			this.resizeWindow(this.state.smallScreen);
		});
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize');
	}

	resizeWindow = () => {
		this.props.onResize(this.state.smallScreen)
	}

	changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	}

	generateKey = (prefix, idx) => {
		return prefix + idx;
	}

	//Small Screens
	createMenuMobile = () => {
		return (
			<div>
				<AppBar className={this.props.classes.appBar}>
					<Toolbar>
						<Grid container direction="row" justify="flex-start" alignItems="center">
							<MenuIcon
								className={this.props.classes.sideBarIcon}
								onClick={() => { this.setState({ drawer: true }) }} />
							<Typography variant="h6">
								SCADT
              </Typography>
						</Grid>
					</Toolbar>
				</AppBar>
				<SwipeableDrawer
					open={this.state.drawer}
					onClose={() => { this.setState({ drawer: false }) }}
					onOpen={() => { this.setState({ drawer: true }) }}>
					<div tabIndex={0} role="button"
						onClick={() => { this.setState({ drawer: false }) }}
						onKeyDown={() => { this.setState({ drawer: false }) }}>

						<List className={this.props.classes.list}>
							{this.links.map((link, idx) => {
								return (
									<ListItem key={this.generateKey('li_left_menu', idx)} button divider>
										<Button>
											<Link style={{ textDecoration: 'none', color: '#2a2a2a' }} to={link.url} color="inherit" key={this.generateKey('lnk_left_menu', idx)}>{link.text}</Link>
										</Button>
									</ListItem>
								)
							})}
							<ListItem>
								<Link to={"/"} className={this.props.classes.img} onClick={() => this.changeLanguage('ro')}><FlagIcon code="ro" size="lg" /></Link>
								<Link to={"/"} className={this.props.classes.img} onClick={() => this.changeLanguage('en')}><FlagIcon code="us" size="lg" /></Link>
								<Link to={"/"} className={this.props.classes.img} onClick={() => this.changeLanguage('de')}><FlagIcon code="de" size="lg" /></Link>
							</ListItem>
							<ListItem>
								<div className="divScadt">
									<p>
										<img src="/images/home.gif" width="16" height="16" alt="" />
										&nbsp;Str. Cireasov, Nr. 18, Slatina, OLT</p>
									<p>
										<img src="/images/phone.gif" width="16" height="16" alt="" />
										&nbsp;0249/434.246</p>
									<p>
										<img src="/images/fax.gif" width="16" height="16" alt="" />
										&nbsp;0249/436.601</p>
									<p>
										<img src="/images/email.gif" width="16" height="16" alt="" />
										&nbsp;office@scadt.ro</p>
								</div>
							</ListItem>
						</List>
					</div>
				</SwipeableDrawer>
			</div>
		);
	}

	//Larger Screens
	createMenuDesktop = () => {
		return (
			<AppBar className={this.props.classes.appBar}>
				<Toolbar>
					<div>
						<img src="/images/logoSCADTNew.png" alt="SCADT Slatina" />
					</div>
					{this.links.map((link, idx) => {
						return (
							<Link to={link.url} className={this.props.classes.link} color="inherit" key={this.generateKey('link_top', idx)}>{link.text}</Link>
						)
					})}
					<Link to={"/"} className={this.props.classes.img} onClick={() => this.changeLanguage('ro')}><FlagIcon code="ro" size="lg" /></Link>
					<Link to={"/"} className={this.props.classes.img} onClick={() => this.changeLanguage('en')}><FlagIcon code="us" size="lg" /></Link>
					<Link to={"/"} className={this.props.classes.img} onClick={() => this.changeLanguage('de')}><FlagIcon code="de" size="lg" /></Link>
				</Toolbar>
			</AppBar>
		)
	}

	render = () => {
		const { t } = this.props;
		this.links = [
			{ text: t("menu_aboutus"), url: "/" },
			{ text: t("menu_projects"), url: "/proiecte" },
			{ text: t("menu_tehnical_base"), url: "/dotare" },
			{ text: t("menu_certifications"), url: "/certificari" },
			{ text: t("menu_structure"), url: "/structura" },
			{ text: t("menu_financial"), url: "/financiar" },
			{ text: t("menu_portfolio"), url: "/portfolio" }
		];

		return (
			<div>
				{this.state.smallScreen ? this.createMenuMobile() : this.createMenuDesktop()}
			</div>
		);
	}
}

ResAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
	onResize: PropTypes.func.isRequired
};

export default withTranslation()(withStyles(styleSheet)(ResAppBar));