import React from 'react';
import { withTranslation } from "react-i18next";
import { List, ListItem, ListItemText, Grid, withStyles } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import '../Styles/Home.css';
import PageTitle from '../Components/PageTitle'
import Translations from '../Translations/TehnicalBase'

const styleSheet = {
	root: {
		flexGrow: 1,
	},
	item: {
		paddingLeft: 5
	},
	text: {
		paddingLeft: 10,
		display: "block"
	},
	link: {
		display: "flex",
		textTransform: "capitalize"
	},
	icon: {
		width: 20,
		height: 20,
		paddingLeft: 5
	}
};

class TehnicalBase extends React.Component {
	constructor(props) {
		super();
	}

	generateKey = (prefix, idx) => {
		return prefix + idx;
	}

	render = () => {
		const { t } = this.props;
		const translations = Translations(t);

		this.technical = translations.technical;

		return (<div className={this.props.classes.root}>
			<Grid container direction="column" spacing={1}>
				<Grid item xs={12}>
					<PageTitle labelAboutUs={t("menu_home")} labelCurrentPage={t("menu_tehnical_base")} labelType={4} />
				</Grid>
				<Grid item xs={12}>
					<List dense={true}>
						{this.technical.map((technical, idx) => {
							return (
								<ListItem key={this.generateKey('list_item', idx)} className={this.props.classes.item}>
									{
										technical.icon ? <BuildIcon color="action" className={this.props.classes.icon} /> : null
									}
									<ListItemText primary={technical.text} classes={{primary: this.props.classes.text}} />
								</ListItem>
							)
						})}
					</List>
				</Grid>
			</Grid>
		</div>)
	}
}

export default withStyles(styleSheet)(withTranslation()(TehnicalBase));