import React from 'react';
import { withTranslation } from "react-i18next";
import { List, ListItem, ListItemText, Grid, withStyles } from '@material-ui/core';
import InfoBox from "../Components/InfoBox"
import AppsIcon from '@material-ui/icons/Apps';
import '../Styles/Home.css';
import PageTitle from '../Components/PageTitle'
import Translations from '../Translations/Home'

const styleSheet = {
	root: {
		flexGrow: 1
	},
	item: {
		paddingLeft: 5
	},
	text: {
		paddingLeft: 10,
		display: "block"
	},
	link: {
		display: "flex",
		textTransform: "capitalize"
	},
	icon: {
		width: 20,
		height: 20,
		paddingLeft: 5
	}
};

class Home extends React.Component {
	constructor(props) {
		super();
	}

	generateKey = (prefix, idx) => {
		return prefix + idx;
	}

	render = () => {
		const { t } = this.props;
		const translations = Translations(t);

		this.thumbs = translations.thumbs;
		this.skills = translations.skills;

		return (<div className={this.props.classes.root}>
			<Grid container direction="column" spacing={1}>
				<Grid item xs={12}>
					<PageTitle labelAboutUs={t("menu_aboutus")} />
				</Grid>
				<Grid item xs={12}>
					<List>
						{this.skills.map((skill, idx) => {
							return (
								<ListItem key={this.generateKey('list_item', idx)} className={this.props.classes.item}>
									{
										skill.icon ? <AppsIcon color="action" className={this.props.classes.icon} /> : null
									}
									<ListItemText primary={skill.text} classes={{ primary: this.props.classes.text }} />
								</ListItem>
							)
						})}
					</List>
				</Grid>
				<Grid item xs={12}>
					<Grid container
						direction="row"
						justify="flex-start"
						alignItems="flex-start" spacing={2}>
						{this.thumbs.map((thumb, idx) => {
							return (
								<Grid item key={this.generateKey('grid_item', idx)} xs={4}>
									<InfoBox img={thumb.img}
										title={thumb.title}
										desc={thumb.desc}
										key={this.generateKey('img_thumb', idx)}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		</div >)
	}
}

export default withStyles(styleSheet)(withTranslation()(Home));