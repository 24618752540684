import React from 'react';
import { withTranslation } from "react-i18next";
import ResAppBar from './Components/RespAppBar';
import PropTypes from 'prop-types';

class Header extends React.Component {
	constructor(props) {
		super();
	}

	handleResize = () => {
		this.props.onResize(window.innerWidth > 600)
	}

	render = () => {
		return (<div className="Header">
			<ResAppBar onResize={this.handleResize} />
		</div>);
	}
}

ResAppBar.propTypes = {
	onResize: PropTypes.func.isRequired
};

export default withTranslation()(Header);