import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Grid, Typography, withStyles } from '@material-ui/core';

const styleSheet = {
	avatar: {
		width: 90,
		height: 90
	}
};

class InfoBox extends React.Component {
	render() {
		return (
			<Grid container
				direction="row"
				justify="center"
				alignItems="center">
				<Grid item>
					<Avatar src={this.props.img} className={this.props.classes.avatar} />
				</Grid>
				<Grid item xs>
					<Typography variant="body2">{this.props.title}</Typography>
					<Typography variant="caption">{this.props.desc}</Typography>
				</Grid>
			</Grid>
		);
	}
}

InfoBox.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styleSheet)(InfoBox);