import React from 'react';
import { withTranslation } from "react-i18next";
import { Grid, GridList, GridListTile, withStyles } from '@material-ui/core';
import PageTitle from '../Components/PageTitle'
// import Translations from '../Translations/Portfolio'

const styleSheet = {
  root: {
		paddingLeft: 10,
		flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden'
	},
	title: {
		paddingLeft: 0
	},
	gridList: {

	},
	img: {
    borderRadius: 4,
    borderWidth: 1,
		borderColor: "#c0c0c0",
		width: "107px",
		height: "72px"
	}
};

class Portfolio extends React.Component {
	constructor(props) {
		super();
	}

	generateKey = (prefix, idx) => {
		return prefix + idx;
	}

	render = () => {
		const cols = window.innerWidth > 600 ? 8 : 4;
		const { t } = this.props;

		return (<div className={this.props.classes.root}>
			<Grid container direction="row" spacing={1}>
				<Grid item xs={12}>
					<PageTitle className={{primary: this.props.classes.title}} labelAboutUs={t("menu_home")} labelCurrentPage={t("menu_portfolio")} labelType={6} />
				</Grid>
				<Grid item xs={12}>
					<GridList className={this.props.classes.gridList} cols={cols}>
						{[...Array(14).keys()].map((index) => {
							let imgSourceSmall = "http://www.scadt.ro/Images/GalleryNew/picture" + (index+1) + "_Small.jpg";
							let imgSource = "http://www.scadt.ro/Images/GalleryNew/picture" + (index+1) + ".jpg";
							return (<GridListTile key={index}>
								<a href={imgSource} rel="lightbox[technicalBase]">
									<img src={imgSourceSmall} alt="" className={this.props.classes.img} />
								</a>
							</GridListTile>);
						})}
					</GridList>
				</Grid>
			</Grid>
		</div >);
	}
}

export default withStyles(styleSheet)(withTranslation()(Portfolio));