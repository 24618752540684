const CERTIFICATIONS_COUNT = 19;

export default function (t) {
	return {
		certifications: [...Array(CERTIFICATIONS_COUNT).keys()].map((idx) => {
			idx = idx + 1;
			if (idx === 1)
				return {text: t("certification_" + idx) }
			else
			return { icon: true, text: t("certification_" + idx) }
		})/*,
		text: t("certification_text")*/
	}
}