export default function (t) {
	return {
		ds1: [
			{
				"desc": t("structure_table_job_1"),
				"interval1": 1,
				"interval2": 1,
				"interval3": 7
			},
			{
				"desc": t("structure_table_job_2"),
				"interval1": 0,
				"interval2": 1,
				"interval3": 1
			},
			{
				"desc": t("structure_table_job_3"),
				"interval1": 0,
				"interval2": 1,
				"interval3": 1
			},
			{
				"desc": t("structure_table_job_4"),
				"interval1": 0,
				"interval2": 0,
				"interval3": 1
			},
			{
				"desc": t("structure_table_job_5"),
				"interval1": 1,
				"interval2": 1,
				"interval3": 2
			},
			{
				"desc": t("structure_table_job_6"),
				"interval1": 1,
				"interval2": 1,
				"interval3": 2
			},
			{
				"desc": t("structure_table_job_7"),
				"interval1": 1,
				"interval2": 1,
				"interval3": 1
			},
			{
				"desc": t("structure_table_job_8"),
				"interval1": 1,
				"interval2": 1,
				"interval3": 1
			},
			{
				"desc": t("structure_table_job_9"),
				"interval1": 1,
				"interval2": 1,
				"interval3": 1
			},
			{
				"desc": t("structure_table_job_10"),
				"interval1": 1,
				"interval2": 1,
				"interval3": 1
			}
		],
		ds2: [
			{
				"desc": t("structure_table_job_11"),
				"interval1": 1,
				"interval2": 1,
				"interval3": 1
			},
			{
				"desc": t("structure_table_job_12"),
				"interval1": 0,
				"interval2": 1,
				"interval3": 0
			},
			{
				"desc": t("structure_table_job_13"),
				"interval1": 4,
				"interval2": 6,
				"interval3": 4
			}
		]
	}
}