const SKILLS_COUNT = 6;

export default function (t) {
	return {
		thumbs: [...Array(SKILLS_COUNT).keys()].map((idx) => {
			idx = idx + 1;
			return {
				img: "/images/thumb_" + idx + ".png",
				title: t("thumb_" + idx + "_title"),
				desc: t("thumb_" + idx + "_desc")
			}
		}),
		skills: [{ text: t("home_text") }, ...[...Array(SKILLS_COUNT).keys()].map((idx) => {
			idx = idx + 1;
			return { icon: true, text: t("home_skill_" + idx) }
		})]
	}
}