import React from 'react';
import { withTranslation } from "react-i18next";
import { List, ListItem, ListItemText, Grid, withStyles } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import PageTitle from '../Components/PageTitle'
import Translations from '../Translations/Certifications'

const styleSheet = {
	item: {
		paddingLeft: 15
	}
};

class Certifications extends React.Component {
	generateKey = (prefix, idx) => {
		return prefix + idx;
	}

	render = () => {
		const { t } = this.props;
		const translations = Translations(t);

		this.text = translations.text;
		this.certifications = translations.certifications;

		return (<div className={this.props.classes.root}>
			<Grid container direction="column" spacing={1}>
				<Grid item xs={12}>
					<PageTitle labelAboutUs={t("menu_home")} labelCurrentPage={t("menu_certifications")} labelType={3} />
				</Grid>
				<Grid item xs={12}>
					<List dense={true}>
						<ListItemText primary={this.text} />
						{this.certifications.map((certification, idx) => {
							return (
								<ListItem key={this.generateKey('list_item', idx)} className={this.props.classes.item}>
									{
										certification.icon ? <BookmarkIcon color="action" /> : null
									}
									<ListItemText primary={certification.text} />
								</ListItem>
							)
						})}
					</List>
				</Grid>
			</Grid>
		</div>);
	}
}

export default withStyles(styleSheet)(withTranslation()(Certifications));