import React from 'react';
import { withTranslation } from "react-i18next";
import { List, ListItem, ListItemText, Grid, withStyles } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PageTitle from '../Components/PageTitle'
import Translations from '../Translations/Projects'

const styleSheet = {
	root: {
		flexGrow: 1,
	},
	item: {
		paddingLeft: 10
	},
	text: {
		paddingLeft: 10,
		display: "block"
	}
};

class Projects extends React.Component {
	generateKey = (prefix, idx) => {
		return prefix + idx;
	}

	render = () => {
		const { t } = this.props;
		const translations = Translations(t);

		this.text = translations.text;
		this.projects = translations.projects;

		return (<div className={this.props.classes.root}>
			<Grid container direction="column" spacing={1}>
				<Grid item xs={12}>
					<PageTitle labelAboutUs={t("menu_home")} labelCurrentPage={t("menu_projects")} labelType={1} />
				</Grid>
				<Grid item xs={12}>
					<List>
						<ListItemText primary={this.text} />
						{this.projects.map((project, idx) => {
							return (
								<ListItem key={this.generateKey('list_item', idx)} className={this.props.classes.item}>
									<AssignmentIcon color="action" className={this.props.classes.icon} /><ListItemText primary={project} classes={{ primary: this.props.classes.text }} />
								</ListItem>
							)
						})}
					</List>
				</Grid>
			</Grid>
		</div>);
	}
}

export default withStyles(styleSheet)(withTranslation()(Projects));