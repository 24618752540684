import React from 'react';
import MapContainer from './Components/MapContainer'

class Left extends React.Component {
	UNSAFE_componentWillMount() {
	}

	render() {
		return (
			<div className="App-content-left">
				<div className="divScadt">
					<p>
						Societate de</p>
					<p>
						Constructii,</p>
					<p>
						Aprovizionare,</p>
					<p>
						Desfacere si</p>
					<p>
						Transport</p>
				</div>
				<hr className="hrScadt" />
				<div className="divScadt">
					<p>Contact</p>
					<p>
						<img src="/images/home.gif" width="16" height="16" alt="" />
						&nbsp;Str. Cireasov, Nr. 18, Slatina, OLT</p>
					<p>
						<img src="/images/phone.gif" width="16" height="16" alt="" />
						&nbsp;0249/434.246</p>
					<p>
						<img src="/images/fax.gif" width="16" height="16" alt="" />
						&nbsp;0249/436.601</p>
					<p>
						<img src="/images/email.gif" width="16" height="16" alt="" />
						&nbsp;office@scadt.ro</p>
				</div>
				<hr className="hrScadt" />
				<div className="divScadt">
					<p>Certificari</p>
					<div>
						<img src="/images/logo_iqnet.gif" alt="IQNET" />&nbsp;
            <img src="/images/logo_srac.gif" alt="SRAC" />&nbsp;
            <img src="/images/logo_renar.png" alt="RENAR" />
					</div>
				</div>
				<hr className="hrScadt" />
				<div className="divScadt">
					<p>Harta</p>
					<MapContainer />
				</div>
			</div>
		)
	}
}

export default Left;