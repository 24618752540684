import React from 'react';
import { withTranslation } from "react-i18next";
import { Grid, Table, Typography, withStyles } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageTitle from '../Components/PageTitle'
import Translations from '../Translations/Financial'

const styleSheet = {
	root: {
		flexGrow: 1,
		width: '99%',
		overflowX: 'auto',
		overflowY: 'auto'
	},
  table: {
		minWidth: 600,
		minHeight: 475
  }
};

class Financial extends React.Component {
	generateKey = (prefix, idx) => {
		return prefix + idx;
	}

	render = () => {
		const { t } = this.props;
		const translations = Translations(t);

		this.text = translations.text;
		this.rows = translations.ds;

		return (<div className={this.props.classes.root}>
			<Grid container direction="column" spacing={1}>
				<Grid item xs={12}>
					<PageTitle labelAboutUs={t("menu_home")} labelCurrentPage={t("menu_financial")} labelType={5} />
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" style={{ paddingLeft: '15px' }}>{t("financial_text")}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Table size="medium" className={this.props.classes.table}>
						<TableHead>
							<TableRow>
								<TableCell style={{ fontWeight: 'bold', fontSize: '0.875rem', color: '#000000' }}>{t("financial_table_col_1")}</TableCell>
								<TableCell></TableCell>
								<TableCell style={{ fontWeight: 'bold', fontSize: '0.875rem', color: '#000000' }} align="right">{t("financial_table_col_2")}</TableCell>
								<TableCell style={{ fontWeight: 'bold', fontSize: '0.875rem', color: '#000000' }} align="right">{t("financial_table_col_3")}</TableCell>
								<TableCell style={{ fontWeight: 'bold', fontSize: '0.875rem', color: '#000000' }} align="right">{t("financial_table_col_4")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.rows.map(row => (
								<TableRow key={row.desc}>
									<TableCell>{row.desc}</TableCell>
									<TableCell>{row.operation}</TableCell>
									<TableCell align="right">{row.indicator1}</TableCell>
									<TableCell align="right">{row.indicator2}</TableCell>
									<TableCell align="right">{row.indicator3}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</div>)
	}
}

export default withStyles(styleSheet)(withTranslation()(Financial));