import React, { Component } from 'react'
import './Styles/App.css';
import Header from './Header';
import Left from './Left';
import Right from './Right'
import Footer from './Footer';
import { BrowserRouter } from 'react-router-dom';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = { showLeft: window.innerWidth > 600 };
	}

	handleResize = () => {
		this.setState({ showLeft: window.innerWidth > 600 });
	}

	render = () => {
		return (
			<div className="App">
				<BrowserRouter>
					<Header onResize={this.handleResize} />
					<div className="App-content">
						{this.state.showLeft ? <Left /> : null}
						<Right />
					</div>
					<Footer />
				</BrowserRouter>
			</div>
		);
	}
}

export default App;
