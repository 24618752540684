import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { Link, Breadcrumbs, Paper, withStyles } from "@material-ui/core";
//import './Styles/Footer.css';
import Translations from './Translations/Footer'

const styleSheet = {
	root: {
		justifyContent: "center",
		flexWrap: "wrap",
		background: "#2a2a2a"
	},
	paper: {
		background: "transparent",
		color: "white"
	},
	link: {
		color: "white",
		fontSize: 14
	},
	separator: {
		color: "white"
	}
};

class Footer extends React.Component {
	constructor(props) {
		super();
	}

	generateKey = (prefix, idx) => {
		return prefix + "_" + idx;
	}

	render = () => {
		const { classes, t } = this.props;
		const translations = Translations(t);
		this.links = translations.links;

		return (<div className="Footer">
			<Paper elevation={0} className={classes.paper}>
				<Breadcrumbs aria-label="breadcrumb" separator="|">
					{this.links.map((link, idx) => {
						return (
							<Link className={classes.link} key={this.generateKey("link_bottom", idx)} href={link.url}>{link.text}</Link>
						)
					})}
				</Breadcrumbs>
				&copy; 2019 SCADT
			</Paper>
		</div>);
	}
}

Footer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styleSheet)(withTranslation()(Footer));